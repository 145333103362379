@use "imports/variables.scss" as *;
@use "~sass-mq/mq.scss";

.benefits {
  &__cards {
    display: flex;
    flex-wrap: wrap;
    margin: -60px -2% 0 -2%;
  }

  &__card {
    padding: 60px 2% 0 2%;
    flex-basis: 25%;
  }

  @include mq.mq($until: desktop) {
    &__card {
      flex-basis: 50%;
    }
  }

  @include mq.mq($until: largeTablet) {
    &__card {
      margin: -40px -10px 0 -10px;
    }

    &__card {
      padding: 40px 10px 0 10px;
    }
  }

  @include mq.mq($until: tablet) {
    &__card {
      flex-basis: 50%;
      margin: 0;
    }
  }

  &__description {
    margin-top: 0.5em;
    font-size: 18px;
    text-align: center;
  }
}
