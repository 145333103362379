@use "imports/variables.scss" as *;

.cardbox {
  position: relative;
  text-decoration: none;

  &__container {
    position: relative;
    width: 100%;
    padding-top: 80%;
  }

  &__background,
  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 30%;
    // background: $green-1;
    // filter: drop-shadow(0px 0px 10px $green-1) saturate(1.2);

    &--green {
      background: $green-1;
      filter: drop-shadow(0px 0px 10px $green-1) saturate(1.2);
    }

    &--pink {
      background: $pink;
      filter: drop-shadow(0px 0px 10px $pink) saturate(1.2);
    }

    &--discord {
      background: $discord;
      filter: drop-shadow(0px 0px 10px $discord) saturate(1.2);
    }

    &--orange {
      background: $orange;
      filter: drop-shadow(0px 0px 10px $orange) saturate(1.2);
    }

    &--blue {
      background: $blue-1;
      filter: drop-shadow(0px 0px 10px $blue-1) saturate(1.2);
    }
  }

  &__background {
    transform: translate(-2px, 2px);
  }

  &__image {
    transition: all 0.15s ease-in-out;
    transform: translate(2px, -2px);

    a.cardbox:hover & {
      transform: translate(6px, -6px);
    }

    a.cardbox:active & {
      transform: translate(-2px, 2px);
    }

    & > img {
      width: 100%;
      height: 100%;
      border-radius: 30%;
      object-fit: cover;
      contain-intrinsic-size: 220px 200px;
    }
  }

  &__name {
    position: relative;
    display: block;
    text-align: center;
    font-size: 1.25rem;
    font-weight: $semibold;
    color: white;
    margin-top: 16px;
    transition: all 0.15s ease-in-out;

    a.cardbox:hover & {
      text-decoration: underline;
    }
  }
}
