@use "imports/variables.scss" as *;
@use "~sass-mq/mq.scss";

.table {
  border-radius: 10px;
  &__heading {
    background: $pink;
    color: white;
    font-weight: $semibold;
    padding: 12px;
    border-radius: 4px 4px 0 0;
    margin-top: 0 !important;
    margin-bottom: 0;
    font-size: 32px;
  }

  &__row {
    display: flex;
    border-left: 1px solid $dark-blue-3;
    border-right: 1px solid $dark-blue-3;

    &:nth-child(2n) {
      background-color: $dark-blue-3;
    }

    &:last-child {
      border-radius: 0 0 4px 4px;
      border-bottom: 1px solid $dark-blue-3;
    }
  }

  &__row {
    &:nth-child(2n) {
      .read-more__footer {
        background-color: $dark-blue-3;
      }
      .read-more__footer:before {
        background-image: linear-gradient(rgba(255, 255, 255, 0) 0%, $dark-blue-3 100%);
      }
    }
  }

  &__time {
    flex: 0 0 80px;
    padding: 16px 0;
    width: 80px;
    font-weight: $semibold;
    text-align: center;
    border-right: 2px solid $pink;

    @include mq.mq($until: tablet) {
      padding: 8px 0;
    }
  }

  &__event {
    flex: 1 1 auto;
    padding: 16px 32px;
    font-weight: $semibold;

    @include mq.mq($until: tablet) {
      padding: 8px 16px;
    }
  }

  &__event-title {
    font-size: 20px;

    @include mq.mq($until: tablet) {
      font-size: 18px;
    }
  }

  &__event-link {
    color: $pink-light;
  }

  &__description {
    font-weight: $regular;
    font-size: 16px;
  }

  &__description a {
    color: $pink-light;
  }
  &__description p {
    margin-bottom: 0.5rem;
  }

  &__speaker {
    font-weight: $regular;
  }

  &__content {
    margin: 1rem 0;
    font-weight: $regular;

    p + p {
      margin-top: 1rem;
    }
  }
}
