@use "imports/variables.scss" as *;
@use "~sass-mq/mq.scss";

.profile {
  position: relative;
  text-decoration: none;

  &__container {
    position: relative;
    width: 100%;
    padding-top: 120%;
  }

  &__background,
  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 30%;
    background: $orange;
    box-shadow: 0px 0px 10px $orange;

    .profile--keynote & {
      background: $pink;
      box-shadow: 0px 0px 10px $pink;
    }

    .profile--mc & {
      background: $dark-blue-2;
    }

    .profile--cfp & {
      background: $sessionize;
      box-shadow: 0px 0px 10px $sessionize;
    }

    .profile--tba & {
      background: $dark-blue-4;
      box-shadow: 0px 0px 10px $dark-blue-4;
    }
  }

  &__background {
    transform: translate(-2px, 2px);
  }

  &__image {
    transition: all 0.15s ease-in-out;
    transform: translate(2px, -2px);

    a.profile:hover & {
      transform: translate(6px, -6px);
      box-shadow: 0px 0px 15px $orange;
    }
    a.profile--keynote:hover & {
      box-shadow: 0px 0px 15px $pink;
    }

    a.profile:active & {
      transform: translate(-2px, 2px);
    }

    & > img {
      width: 100%;
      height: 100%;
      border-radius: 30%;
      object-fit: cover;
      contain-intrinsic-size: 200px 200px;
    }
  }

  &__keynote {
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    background: $pink-light;
    color: #fff;
    font-size: 10px;
    font-weight: $semibold;
    letter-spacing: 0.1em;
    padding: 2px 6px;
    border-radius: 20px;
  }

  &__mc {
    @extend .profile__keynote;
    background: $dark-blue-1;
  }

  &__name {
    position: relative;
    display: block;
    text-align: center;
    font-size: 1.2rem;
    font-weight: $semibold;
    color: white;
    margin-top: 16px;
    transition: all 0.15s ease-in-out;

    & > svg {
      width: 18px;
      height: 18px;
      transform: translateY(2px);
    }

    a.profile:hover & {
      text-decoration: underline;
    }
  }

  &__talk_title {
    position: relative;
    display: block;
    text-align: center;
    font-size: 0.75rem;
    font-weight: $regular;
    color: $pink;
    margin-top: 0;
    transition: all 0.15s ease-in-out;
    text-decoration: none;
  }
}
