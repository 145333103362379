@use "~sass-mq/mq.scss";
@use "~photoswipe/photoswipe";

.photos {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 1em;

  @include mq.mq($until: largeTablet) {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }

  img {
    aspect-ratio: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
