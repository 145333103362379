@use "imports/variables.scss" as *;
@use "~sass-mq/mq.scss";

.supporters {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1.25rem;

  &__listing {
    padding: 0;
    margin: 0;
    list-style-type: none;
    width: 100%;
    max-width: 800px;
  }

  &__supporter {
    display: inline-block;
  }

  &__supporter:not(:first-child)::before {
    content: "⸺";
    padding: 0 0.5em;
  }
}
