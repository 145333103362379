@use "imports/variables.scss" as *;
@use "~sass-mq/mq.scss";

.schedule {
  &__table,
  &__calendar {
    @include mq.mq($until: wide) {
      margin-top: 20px;
    }
  }

  &__calendar {
    margin-bottom: 1em;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    list-style: none;
    padding: 0;
    max-width: 380px;

    li a {
      display: block;
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    img {
      width: 40px;
      height: 40px;
      margin-right: 16px;
    }
  }

  &__calendar_url {
    display: flex;
    flex-direction: row;

    button {
      width: 140px;
      border: 1px solid $pink;
      border-radius: 5px;
      height: 60px;
      margin-left: 12px;
      padding: 0 24px;
      color: $dark-blue-2;
      transition: color 0.25s ease;
      color: $pink;
      cursor: pointer;

      transition: box-shadow 150ms cubic-bezier(0.236, 0, 0.236, 1);
      @media (prefers-reduced-motion: reduce) {
        transition: none;
      }

      &:hover {
        box-shadow: 0 0 6px 0 $pink;
      }
    }
    @include mq.mq($until: tablet) {
      flex-direction: column;
      button {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}
