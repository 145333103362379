// be-vietnam-pro-500 - latin-ext_latin
@font-face {
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 500;
  src: local(""),
    url("../fonts/be-vietnam-pro-v8-latin-ext_latin-500.woff2") format("woff2"), // Chrome 26+, Opera 23+, Firefox 39+
    url("../fonts/be-vietnam-pro-v8-latin-ext_latin-500.woff") format("woff"); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
}
// be-vietnam-pro-500italic - latin-ext_latin
@font-face {
  font-family: "Be Vietnam Pro";
  font-style: italic;
  font-weight: 500;
  src: local(""),
    url("../fonts/be-vietnam-pro-v8-latin-ext_latin-500italic.woff2") format("woff2"), // Chrome 26+, Opera 23+, Firefox 39+
    url("../fonts/be-vietnam-pro-v8-latin-ext_latin-500italic.woff") format("woff"); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
}
// be-vietnam-pro-700 - latin-ext_latin
@font-face {
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 700;
  src: local(""),
    url("../fonts/be-vietnam-pro-v8-latin-ext_latin-700.woff2") format("woff2"), // Chrome 26+, Opera 23+, Firefox 39+
    url("../fonts/be-vietnam-pro-v8-latin-ext_latin-700.woff") format("woff"); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
}
// be-vietnam-pro-700italic - latin-ext_latin
@font-face {
  font-family: "Be Vietnam Pro";
  font-style: italic;
  font-weight: 700;
  src: local(""),
    url("../fonts/be-vietnam-pro-v8-latin-ext_latin-700italic.woff2") format("woff2"), // Chrome 26+, Opera 23+, Firefox 39+
    url("../fonts/be-vietnam-pro-v8-latin-ext_latin-700italic.woff") format("woff"); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
}
// be-vietnam-pro-800 - latin-ext_latin
@font-face {
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 800;
  src: local(""),
    url("../fonts/be-vietnam-pro-v8-latin-ext_latin-800.woff2") format("woff2"), // Chrome 26+, Opera 23+, Firefox 39+
    url("../fonts/be-vietnam-pro-v8-latin-ext_latin-800.woff") format("woff"); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
}
// be-vietnam-pro-800italic - latin-ext_latin
@font-face {
  font-family: "Be Vietnam Pro";
  font-style: italic;
  font-weight: 800;
  src: local(""),
    url("../fonts/be-vietnam-pro-v8-latin-ext_latin-800italic.woff2") format("woff2"), // Chrome 26+, Opera 23+, Firefox 39+
    url("../fonts/be-vietnam-pro-v8-latin-ext_latin-800italic.woff") format("woff"); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
}
