@use "imports/variables.scss" as *;
@use "~sass-mq/mq.scss";

.sponsors {
  &__card {
    position: relative;
    max-width: calc(50% - 20px);
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mq.mq($until: desktop) {
      max-width: calc(100% - 20px);
    }
  }

  &__link {
    position: relative;
    flex-basis: calc(50% - 20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0 10px 20px;
    background: #fff;
    padding: 20px 40px;
    border-radius: 50px;
    text-decoration: none;
    transition: all 0.15s ease-in-out;
    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }

    &:hover {
      box-shadow: inset 0 0 0 2px $teal;
    }
  }

  &__sponsor-description {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    color: $grey-1;
    padding: 20px 0 10px 0;
    margin-top: 20px;
    font-size: 14px;
  }

  &__tag {
    position: absolute;
    top: -10px;
    background: $grey-3;
    color: #fff;
    font-size: 12px;
    letter-spacing: 0.2em;
    font-weight: $bold;
    padding: 3px 7px 3px 10px;
    border-radius: 256px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;

    &:not(:first-of-type) {
      margin-top: 20px;
    }

    & img {
      margin-top: 10px;
      max-width: 200px;
      max-height: 100px;
      contain-intrinsic-size: 200px 100px;
    }
    &--community {
      .sponsors__tag {
        background: $blue-1;
      }
    }
    &--bronze {
      .sponsors__tag {
        background: $bronze;
      }
    }

    &--silver {
      .sponsors__tag {
        background: $silver;
      }
    }

    &--gold {
      .sponsors__tag {
        background: $gold;
      }
    }

    &--travel {
      .sponsors__tag {
        background: $green-2;
      }
    }

    &--ruby {
      .sponsors__tag {
        background: $ruby;
      }

      .sponsors__link {
        padding: 20px 40px;
      }

      img {
        max-width: 300px;
      }

      .sponsors__sponsor-description {
        font-size: 16px;
      }
    }

    &--bronze,
    &--silver,
    &--travel,
    &--community {
      .sponsors__link {
        flex-basis: auto;
        justify-content: center;
        padding: 30px 25px;
        height: 90px;
      }

      img {
        margin-top: 0;
        width: 150px;
        max-width: 150px;
      }
    }
  }

  &__text-right {
    @include mq.mq($from: largeTablet) {
      text-align: right;
    }
  }

  @include mq.mq($from: largeTablet) {
    &__list {
      justify-content: flex-end;
      margin-left: 0;
    }
  }
}
