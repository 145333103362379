@use "imports/variables.scss" as *;
@use "~sass-mq/mq.scss";

.hero {
  position: relative;
  width: 100%;
  min-height: 900px;
  height: 100vh;
  max-height: 1600px;
  background: $dark-blue-1;
  overflow: hidden;

  // Improve the performance of the image on scroll on Safari
  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  transform: translateZ(0);

  &__container {
    position: relative;
    max-width: 1300px;
    height: 100%;
    margin: 0 auto;
  }

  &__image-container {
    position: absolute;
    left: 0px;
    top: calc(50% + (#{$header-height} / 2));
    transform: translate3d(0, -50%, 0);
  }

  &__image {
    width: 900px;
    height: auto;
    filter: brightness(1) saturate(2) contrast(1.25) drop-shadow(0px 0px 6px #e0d4ea)
      drop-shadow(0px 0px 8px rgba(224, 212, 234, 0.25));
  }

  &__info {
    position: absolute;
    top: calc(51% + (#{$header-height} / 2) + 5px);
    right: 0px;
    font-size: 1.5rem;
    line-height: 1.4;
    font-weight: $semibold;
    color: $blue-2;

    a {
      color: #fff;
      transition: all 0.1s ease-in-out;

      &:hover {
        color: $dark-blue-4;
      }
    }
  }

  &__cta {
    display: inline-flex;
    align-items: flex-end;
    width: 100%;
    text-decoration: none;
    margin-top: 30px;

    & > span {
      padding-bottom: 7px;
      border-bottom: 3px solid $dark-blue-4;
    }

    & > svg {
      margin-left: 10px;
      transform: translate(0, -11px);
      transition: all 0.15s ease-in-out;
    }

    &:hover > svg {
      transform: translate(10px, -11px);
    }
  }

  &__shadow {
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: -50px;
    left: 0;
    box-shadow: 0px -10px 50px rgba(0, 0, 0, 0.4);
  }

  &__continue {
    position: absolute;
    z-index: 4;
    left: 50%;
    bottom: 10px;
    width: 32px;
    height: 32px;
    transform: translateX(-50%);

    &:focus {
      & > .hero__continue-inner {
        box-shadow: inset 0 0 0 1px $teal;
      }
      outline: none;
    }

    &-inner {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transition: all 0.15s ease-in-out;
      cursor: pointer;

      & > svg {
        width: 100%;
        height: 100%;
      }

      &:hover {
        background: rgba(#000, 0.1);
      }

      &:focus {
        outline: none;
      }
    }
  }

  @include mq.mq($until: superwide) {
    & {
      min-height: 850px;
    }

    &__container {
      max-width: 1200px;
    }

    &__image {
      width: 900px;
    }

    &__info {
      top: calc(51% + (#{$header-height} / 2) + 7px);
      right: -80px;
    }
  }

  @include mq.mq($until: extrawide) {
    & {
      min-height: 650px;
    }

    &__image-container {
      left: 40px;
    }

    &__image {
      width: 700px;
    }

    &__info {
      font-size: 1.3rem;
      right: 40px;
    }
  }

  @include mq.mq($until: wide) {
    & {
      min-height: 600px;
    }

    &__image-container {
      left: 40px;
    }

    &__image {
      width: 600px;
    }

    &__info {
      font-size: 1.3rem;
      right: 40px;
    }
  }

  @include mq.mq($until: desktop) {
    & {
      min-height: auto;
      height: auto;
      max-height: none;
      padding-top: 70px;
    }

    &__image-container {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      padding: 40px;
    }

    &__image {
      position: relative;
      width: 100%;
    }

    &__info {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      padding: 60px 40px 80px 40px;
      margin: 0 auto;
      background: rgba($dark-blue-1, 0.75);
    }
  }

  @include mq.mq($until: tablet) {
    &__image-container {
      padding: 40px 20px;
    }

    &__info {
      padding: 60px 20px 80px 20px;
    }
  }
}
