// Colors
$grey-1: #1d1d1b;
$grey-2: #2f2f2f;
$grey-3: #4a4a4a;
$grey-4: lighten($grey-3, 50%);
$grey-5: lighten($grey-3, 70%);

$purple-1: #3b264d;
$purple-2: #503469;
$purple-3: #d7aaff;
$purple-4: lighten($purple-1, 65%);

$dark-blue-1: #000024;
$dark-blue-2: #181832;
$dark-blue-3: #2d2d45;
$dark-blue-4: lighten($dark-blue-1, 65%);

$blue-1: #4b9bbe;
$blue-2: #93c3d8;
$blue-3: #d7eff7;
$blue-4: #e9f4f8;

$green-1: #599060;
$green-2: #9fcba5;
$green-3: #a8e5b1;
$green-4: #d1e7d5;

$orange: #f3931a;
$pink: #d74090;
$teal: #6bc3c7;
$black: darken(#000024, 5%);

$orange-light: #ffd29a;
$pink-light: #f767b3;
$teal-light: #9be9ed;

$discord: #7288e3;
$sessionize: #1ab394;

$ruby: #e35050;
$gold: #d5a500;
$silver: #b7b7b7;
$bronze: #a17419;

// Fonts
$regular: 500;
$semibold: 700;
$bold: 800;

$monospace: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;

// Header height
$header-height: 70px;

// Using this mixin https://github.com/sass-mq/sass-mq
$breakpoints: (
  mobile: 0px,
  tablet: 640px,
  largeTablet: 768px,
  desktop: 992px,
  wide: 1300px,
  extrawide: 1450px,
  superwide: 1600px,
);

$show-breakpoints: (mobile, tablet, largeTablet, desktop, wide, extrawide, superwide);

@use "~sass-mq/mq.scss" with (
  $breakpoints: $breakpoints,
  // Uncomment the line below to see the breakpoints in the browser
  // $show-breakpoints: $show-breakpoints
);
