@use "imports/variables.scss" as *;
@use "~sass-mq/mq.scss";

form {
  margin-top: 60px;

  color: white;
}
.form-title {
  display: block;
  margin-bottom: 32px;

  font-size: 32px;
  font-weight: $semibold;
}
.form-email,
.form-url {
  display: block;
  width: 100%;
  height: 60px;
  padding: 0 24px;

  border: 2px solid white;
  border-radius: 5px;

  font-size: 18px;
  filter: drop-shadow(1px 1px 3px $pink);

  background-color: white;

  color: $grey-1;
  &::placeholder {
    color: $purple-1;
  }
}
.form-email:focus {
  outline: none;
  border: 2px solid $pink;
}
.form-notices {
  margin: 24px 0 32px;
}
.form-notices__title {
  font-size: 18px;
  font-weight: $semibold;
}
.form-checkbox {
  margin-top: 16px;
  display: flex;
  align-items: center;
}
.form-checkbox__input {
  position: absolute;
  left: 0;
  margin: 0;
  width: 0;
  height: 0;

  opacity: 0;
  overflow: hidden;

  pointer-events: none;
  z-index: -1;
}
.form-checkbox__text {
  position: relative;
  overflow: hidden;
  min-width: 1em;
  min-height: 1em;
  padding-left: 1.25em;
  font-size: 18px;
  cursor: pointer;
}
.form-checkbox__text:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 1em;
  height: 1em;
  box-sizing: border-box;

  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  border-color: #d1d5e0;

  background-color: #ffffff;

  transform: translateY(-50%);
  transition: border-color 50ms cubic-bezier(0.236, 0, 0.236, 1) 100ms,
    border-width 50ms cubic-bezier(0.236, 0, 0.236, 1) 50ms, background-color 50ms cubic-bezier(0.236, 0, 0.236, 1) 0s;
}
.form-checkbox__text:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 1em;
  height: 1em;
  opacity: 0;

  text-align: center;
  color: #fff;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTkgMTljLS41IDAtMS0uMi0xLjQtLjZsLTUtNWMtLjgtLjgtLjgtMiAwLTIuOC44LS44IDItLjggMi44IDBMOSAxNC4ybDkuNi05LjZjLjgtLjggMi0uOCAyLjggMCAuOC44LjggMiAwIDIuOGwtMTEgMTFjLS40LjQtLjkuNi0xLjQuNnoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=");
  background-size: 0.7em;
  background-repeat: no-repeat;
  background-position: center;

  transform: translateY(-50%) scale(0);
  transition: transform 50ms cubic-bezier(0.236, 0, 0.236, 1) 50ms;
}
.form-checkbox__input:checked + .form-checkbox__text {
  color: $pink;
}
.form-checkbox__input:checked + .form-checkbox__text:before {
  border-width: 0.5em;
  border-color: $pink;

  background-color: $pink;
  transition: border-width 50ms cubic-bezier(0.236, 0, 0.236, 1) 0s,
    background-color 50ms cubic-bezier(0.236, 0, 0.236, 1) 50ms;
}
.form-checkbox__input:checked + .form-checkbox__text:after {
  opacity: 1;
  transform: translateY(-50%) scale(1);
}

.form-submit {
  display: block;
  width: 300px;
  max-width: 100%;
  padding: 0 24px;

  border-radius: 60px;

  font-size: 20px;
  line-height: 60px;
  font-weight: $semibold;
  color: #ffffff;

  text-transform: uppercase;
  letter-spacing: 0.15em;

  background-color: $pink;
  transition: background-color 250ms;

  cursor: pointer;

  transition: box-shadow 150ms cubic-bezier(0.236, 0, 0.236, 1);
  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}
.form-submit:hover {
  box-shadow: 0 0 6px 0 $dark-blue-2;
  background-color: $dark-blue-3;
  text-decoration: underline;
  transition: all 250ms;

  @media (prefers-reduced-motion: reduce) {
    text-decoration: underline;
    transition: none;
    background-color: $pink;
    box-shadow: none;
  }
}
.form-submit:focus {
  outline: none;
  box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.3) inset;
}
.form-submit[disabled] {
  background-color: transparent;
  color: $dark-blue-2;
  box-shadow: inset 0 0 0 2px rgba($dark-blue-2, 0.4);
}
.form-inputs > *:not(:first-child) {
  margin-top: 0.5rem;
}
@include mq.mq($until: tablet) {
  .form-title {
    font-size: 24px;
    margin-bottom: 24px;
  }
  .form-email {
    height: 48px;
    font-size: 16px;
  }
  .form-submit {
    line-height: 48px;
    font-size: 16px;
    width: 100%;
  }
  .form-notices__title {
    font-size: 16px;
  }
  .form-checkbox__text {
    font-size: 16px;
  }
}
