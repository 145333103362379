.sponsorship-packages-table {
  max-width: 1200px;
  display: block;
  overflow-x: auto;

  tbody {
    tr {
      td:nth-child(1) {
        white-space: nowrap;
      }
    }
  }
}
